<template>
  <v-dialog
    v-model="activator"
    persistent
    max-width="400px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Success Orders</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              v-for="(orderId, i) in success"
              :key="i"
              cols="12">
              {{ `${i + 1}. ${orderId}` }}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-title>
        <span class="text-h5">Failed Orders</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row
            v-for="(error, i) in errors"
            :key="i"
            no-gutters>
            <v-col cols="12">
              <span class="font-bold">Error code: </span>
              <span>{{ `${error.code}` }}</span>
            </v-col>
            <v-col cols="12">
              <span class="font-bold">Order ID: </span>
              <span>{{ `${error.orderId}` }}</span>
            </v-col>
            <v-col cols="12">
              <span class="font-bold">Message: </span>
              <span>{{ `${error.message}` }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="closeModal()">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    success: {
      type: Array,
      default: () => []
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    activator: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    closeModal () {
      this.activator = false
    }
  }
}
</script>

<style scoped>
.font-bold {
  font-weight: 500;
}
</style>
