export function splitAmoutItems (items = []) {
  const mixAndMacthItems = []
  const nonMixAndMacthItems = []
  const sortItems = items.sort((a, b) => parseFloat(b.unitPrice) - parseFloat(a.unitPrice))
  sortItems.forEach((item) => {
    if (item.mixAndMatch) {
      const mmisNotMM = item.tags.some((tag) => tag === 'notmm')
      const newItem = {
        ...item,
        amount: 1,
        price: item.unitPrice ? item.unitPrice : item.fullPrice / item.amount,
        fullPrice: item.unitPrice ? item.unitPrice : item.fullPrice / item.amount,
        discountPrice: 0
      }
      for (let i = 0; i < parseInt(item.amount); i++) {
        mixAndMacthItems.push({
          ...newItem,
          mmisNotMM
        })
      }
    } else {
      const fullPrice = item.unitPrice ? item.amount * item.unitPrice : item.fullPrice
      nonMixAndMacthItems.push({
        ...item,
        price: fullPrice,
        fullPrice,
        discountPrice: 0
      })
    }
  })
  return {
    mixAndMacthItems,
    nonMixAndMacthItems,
    splitItems: [
      ...mixAndMacthItems,
      ...nonMixAndMacthItems
    ]
  }
}

export function mergeAmountItem (items = []) {
  const mergeItems = []
  items.forEach((item) => {
    const mergeItemIndex = mergeItems.findIndex((mergeItem) => mergeItem.id === item.id)
    const price = parseFloat(item.price) || 0
    const fullPrice = parseFloat(item.fullPrice) || 0
    const discountPrice = parseFloat(item.discountPrice) || 0
    if (mergeItemIndex === -1) {
      mergeItems.push({
        ...item,
        amount: item.amount || 1,
        price,
        fullPrice,
        discountPrice
      })
    } else {
      mergeItems[mergeItemIndex].amount += item.amount || 1
      mergeItems[mergeItemIndex].price += price
      mergeItems[mergeItemIndex].fullPrice += fullPrice
      mergeItems[mergeItemIndex].discountPrice += discountPrice
    }
  })
  return mergeItems
}

export function calculateMixAndMatchPricing (pricing = {}, items = [], isMergeItem = false) {
  const calculatedItems = []
  const lengthItem = items.length
  const itemInTier5 = (lengthItem / 5 | 0) * 5
  const itemInTier3 = ((lengthItem - itemInTier5) / 3 | 0) * 3
  items.forEach((_item, i) => {
    const item = _item
    const price = parseFloat(item.price)
    let mixAndMatch = price
    if (!item.mmisNotMM) {
      if (i >= 0 && i < itemInTier5) {
        mixAndMatch = pricing[price]?.tier5 || price
      } else if (i >= itemInTier5 && (i < itemInTier5 + itemInTier3)) {
        mixAndMatch = pricing[price]?.tier3 || price
      } else {
        mixAndMatch = pricing[price]?.tier1 || price
      }
    }

    delete item.mmisNotMM

    if (isMergeItem) {
      const mergeItemIndex = calculatedItems.findIndex((mergeItem) => mergeItem.id === item.id)
      const itemPrice = mixAndMatch || 0
      const fullPrice = item.fullPrice || 0
      const discountPrice = item.unitPrice - mixAndMatch || 0
      if (mergeItemIndex === -1) {
        calculatedItems.push({
          ...item,
          amount: item.amount || 1,
          price: itemPrice,
          fullPrice,
          discountPrice
        })
      } else {
        calculatedItems[mergeItemIndex].amount += item.amount || 1
        calculatedItems[mergeItemIndex].price += itemPrice
        calculatedItems[mergeItemIndex].fullPrice += fullPrice
        calculatedItems[mergeItemIndex].discountPrice += discountPrice
      }
    } else {
      calculatedItems.push({
        ...item,
        price: mixAndMatch,
        discountPrice: item.unitPrice - mixAndMatch || 0
      })
    }
  })
  return calculatedItems
}

export default (pricing, items) => {
  try {
    const { mixAndMacthItems, nonMixAndMacthItems } = splitAmoutItems(items)
    const calculatedItems = calculateMixAndMatchPricing(pricing, mixAndMacthItems, true)
    const mergeItems = mergeAmountItem([
      ...calculatedItems,
      ...nonMixAndMacthItems
    ])
    return mergeItems
  } catch (error) {
    console.error('MainCalculateMixAndMatchPricing', error)
    throw error
  }
}
