<template>
  <div>
    <v-dialog
      class="order-dialog"
      :value="orderDetailActive"
      max-width="1200"
      width="95%"
      elevation="0"
      @input="closeModal($event)">
      <v-row
        v-if="orderDetailActive"
        id="order-modal"
        class="order-detail-row"
        no-gutters>
        <v-col :cols="7">
          <v-card
            class="order-detail-card"
            height="100%">
            <order-header />
            <v-row class="ma-0 order-product-detail elevation-1">
              <v-col
                :cols="12"
                class="pa-1">
                <order-product-table />
              </v-col>
            </v-row>
            <v-row class="ma-0 order-summary-detail">
              <v-col :cols="12">
                <order-summary-table />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          :cols="3"
          class="col-customer-detail">
          <order-customer-detail @close-modal-request="reFetch()" />
          <order-action
            @regen-success="regenSuccess"
            @close-modal-request="reFetch()" />
        </v-col>
        <v-col :cols="2">
          <order-timeline />
        </v-col>
      </v-row>
    </v-dialog>
    <order-product />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OrderHeader from './OrderHeader.vue'
import OrderProductTable from './OrderProductTable.vue'
import OrderSummaryTable from './OrderSummaryTable.vue'
import OrderCustomerDetail from './OrderCustomerDetail.vue'
import OrderTimeline from './OrderTimeline.vue'
import OrderProduct from './OrderProducts.vue'
import OrderAction from './OrderAction.vue'

export default {
  components: {
    OrderHeader,
    OrderProductTable,
    OrderSummaryTable,
    OrderCustomerDetail,
    OrderTimeline,
    OrderProduct,
    OrderAction
  },
  data () {
    return {
      backupOrderDetail: {},
      fetchedOrderID: '',
      getting: false,
      modalProductActive: false,
      gettingCode: false,
      isEditing: false
    }
  },
  computed: {
    ...mapGetters({
      orderDetailActive: 'Order/orderDetailActive'
    })
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setShippingFee: 'Order/setShippingFee',
      setPromotionCode: 'Order/setPromotionCode',
      setAllSubOrders: 'Order/setAllSubOrders',
      cancelVaildateCode: 'Order/cancelVaildateCode',
      clearSelectedOrderId: 'Order/clearSelectedOrderId',
      updateMemberTel: 'Order/updateMemberTel',
      updateGotMember: 'Order/updateGotMember'
    }),
    regenSuccess () {
      this.$emit('re-fetching')
      this.closeModal()
    },
    reFetch () {
      this.$emit('re-fetching')
      this.closeModal()
    },
    closeModal (value) {
      if (!value) {
        this.clearSelectedOrderId()
        this.cancelVaildateCode()
        this.setPromotionCode('')
        this.setAllSubOrders({})
        this.updateMemberTel('')
        this.updateGotMember(false)
        this.$emit('re-fetching')
        this.$emit('closeModal')
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/gw-variables.scss';

.order-dialog {
  z-index: 900;
}

#order-modal {
  height: 95vh;
  font-size: 16px;
  position: relative;
  .order-detail-card {
    border-radius: 0;
  }
  .order-product-detail {
    height: calc(100% - 285px);
    overflow: auto;
  }
  span.status-dot {
    display: block;
    position: absolute;
    top: 50%;
    left: 175px;
    width: 7px;
    height: 7px;
    margin-left: 12px;
    border-radius: 100%;
    background-color: $bill-status;
    transform: translateY(-50%);
  }
  span.status-dot.status-dot-pending {
    background-color: $bill-status-pending;
  }
  span.status-dot.status-dot-reserved {
    background-color: $bill-status-reserved;
  }
  span.status-dot.status-dot-update_shipping {
    background-color: $bill-status-update_shipping;
  }
  span.status-dot.status-dot-paid {
    background-color: $bill-status-paid;
  }
  span.status-dot.status-dot-waiting {
    background-color: $bill-status-waiting;
  }
  span.status-dot.status-dot-ready_to_ship {
    background-color: $bill-status-readytoship;
  }
  span.status-dot.status-dot-completed {
    background-color: $bill-status-completed;
  }
  span.status-dot.status-dot-void {
    background-color: $bill-status-void;
  }
  span.status-dot.status-dot-exchange_return {
    background-color: $bill-status-exchange_return;
  }
  span.status-dot.status-dot-expired {
    background-color: $bill-status-expired;
  }
  .col-customer-detail {
    position: relative;
  }
  .order-action {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
    padding: 12px;
    width: 100%;
    text-align: center;
    button.v-btn.order-action--btn {
      width: 45%;
      margin-bottom: 15px;
    }
  }
  button.close-modal-icon.v-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    background: #fff;
  }
  .order-detail-header p {
    font-size: 13px;
    margin-bottom: 0;
    position: relative;
  }
  .v-input.v-input--dense {
    font-size: 12px;
    .v-label {
      font-size: 12px;
    }
  }
  .order-detail-row > .col {
    height: 100%;
  }
  .order-summary-detail {
    height: 170px;
  }
  .order-summary-detail .col {
    background-color: #f0f0f0;
  }
  #order-product-table {
    table {
      th {
        height: 28px;
      }
      tr.empty-product:hover {
        background-color: transparent;
      }
      td {
        font-size: 12px;
        vertical-align: top;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .order-product-image {
        max-width: 60px;
        margin-right: 10px;
      }
      .order-product-detail-box {
        display: flex;
      }
    }
  }
  .v-input.order-summary-select.v-input--dense > .v-input__control > .v-input__slot {
    min-height: 25px;
  }
  .v-input.v-text-field--outlined {
    border-radius: 0;
  }
  .v-select.order-summary-select.discount-select {
    width: 20px;
    margin: 0;
  }
  .v-select.order-summary-select {
    width: 120px;
    margin-left: 10px;
    background-color: #ffffff;
    .v-select__selection--comma {
      margin: 0;
      text-transform: uppercase;
    }
    .v-select__selections,
    .v-select__selections input {
      padding: 0 !important;
    }
    .v-input__append-inner {
      margin-top: 0 !important;
      position: relative;
      .v-input__icon {
        min-width: unset;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 7px;
        right: -7px;
        .v-icon.v-icon {
          font-size: 18px;
        }
      }
    }
  }
  .v-input.order-summary-select fieldset {
    border-width: 1px;
  }
  .v-input.order-summary-input {
    fieldset {
      border-width: 1px;
    }
    input {
      padding: 0;
    }
  }
  .v-input.amount-input.order-summary-input {
    .v-input__slot {
      padding: 0 5px;
      input {
        text-align: center;
      }
    }
  }
  .v-input.discount-input input,
  .v-input.shippingfee-input input {
    text-align: right;
  }
  .v-text-field.v-text-field--solo.v-input--dense.order-summary-input {
    > .v-input__control {
      min-height: unset;
      width: 50px;
      > .v-input__slot {
        min-height: 25px;
        box-shadow: unset;
      }
    }
    .v-input__append-outer {
      margin: 0;
      margin-left: 5px;
    }
  }
  .v-input.order-summary-input {
    align-items: center;
  }
}
.order-summary-select-menu{
  p {
    margin: 0;
    font-size: 11px;
  }
  .v-list--dense .v-list-item {
    min-height: 30px;
  }
}
</style>
