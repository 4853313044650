<template>
  <div class="order-address">
    <v-card
      flat>
      <div class="d-flex py-3">
        <v-btn
          v-if="!isEditAddress && !inputDisabled && isMainOrder"
          text
          x-small
          @click="toggleEditAddress()">
          <v-icon x-small>
            mdi-square-edit-outline
          </v-icon>
          <span class="ml-1">
            Edit
          </span>
        </v-btn>
        <div
          v-else
          class="d-flex flex-wrap align-end text-right">
          <v-btn
            v-if="useDefaultAddress && memberId"
            text
            x-small
            @click="toggleAddressMode(false)">
            <v-icon x-small>
              mdi-home-city-outline
            </v-icon>
            <span class="ml-1">
              Manual Address
            </span>
          </v-btn>
          <template v-else-if="memberId">
            <v-btn
              text
              x-small
              @click="addDefaultAddress()">
              <v-icon x-small>
                mdi-home-plus-outline
              </v-icon>
              <span class="ml-1">
                Add address
              </span>
            </v-btn>

            <v-btn
              text
              x-small
              @click="toggleAddressMode(true)">
              <v-icon x-small>
                mdi-home-city-outline
              </v-icon>
              <span class="ml-1">
                User Address
              </span>
            </v-btn>
          </template>
        </div>
      </div>

      <template v-if="isEditAddress">
        <address-box
          v-show="useDefaultAddress"
          :member="member"
          :active="useDefaultAddress"
          @close="toggleAddressMode(false)"
          @edited="editDefaultAddress($event)"
          @select="onSelectDefaultAddress($event)" />
        <v-form
          ref="address-form"
          @submit.prevent="onValidate()">
          <div
            v-show="!useDefaultAddress"
            class="shipping-address-box"
            :style="{ height: `calc(100vh - ${heightOffset} - 148px)`, overflowY: 'auto' }">
            <div class="shipping-address-box-edit">
              <v-text-field
                v-model="editData.firstName"
                label="Firstname"
                color="secondary"
                class="shipping-address-field"
                dense />
              <v-text-field
                v-model="editData.lastName"
                label="Lastname"
                color="secondary"
                class="shipping-address-field"
                dense />
              <v-text-field
                v-model="editData.contactNo"
                label="Tel"
                color="secondary"
                class="shipping-address-field"
                dense />
              <v-text-field
                v-model="editData.email"
                label="Email"
                color="secondary"
                class="shipping-address-field"
                dense />
              <v-text-field
                v-model="editData.taxId"
                label="Tax ID"
                color="secondary"
                class="shipping-address-field"
                dense />
              <v-text-field
                v-model="editData.address"
                label="Address 1"
                color="secondary"
                class="shipping-address-field"
                dense />
              <v-text-field
                v-model="editData.address2"
                label="Address 2"
                color="secondary"
                class="shipping-address-field"
                dense />
              <v-autocomplete
                v-model="editData.country"
                label="Country"
                color="secondary"
                :items="shippingCountries"
                item-text="label"
                item-value="country"
                dense
                class="shipping-address-field" />
              <v-address-field
                v-if="isThailand"
                v-model="editData.subDistrict"
                label="Sub District"
                color="secondary"
                type="sub-district"
                class="shipping-address-field"
                dense
                @select="onSelectAddress($event)" />
              <v-text-field
                v-else
                v-model="editData.subDistrict"
                label="Sub District"
                color="secondary"
                class="shipping-address-field"
                dense />
              <v-address-field
                v-if="isThailand"
                v-model="editData.district"
                label="District"
                color="secondary"
                type="district"
                class="shipping-address-field"
                dense
                @select="onSelectAddress($event)" />
              <v-text-field
                v-else
                v-model="editData.district"
                label="District"
                color="secondary"
                class="shipping-address-field"
                dense />
              <v-address-field
                v-if="isThailand"
                v-model="editData.stateProvince"
                label="State Province"
                color="secondary"
                type="province"
                class="shipping-address-field"
                dense
                @select="onSelectAddress($event)" />
              <v-combobox
                v-else
                v-model="editData.stateProvince"
                :search-input.sync="searchProvince"
                label="State Province"
                color="secondary"
                :items="locations"
                item-text="city"
                item-value="city"
                :rules="[
                  (v) => !!v && !isError.province || 'Field is require.'
                ]"
                :loading="loading"
                dense
                class="shipping-address-field"
                @input="onSelectLocation($event, true)">
                <template #item="{ item }">
                  {{ `${item.city} ${item.postalCode || item.cityDistrict || ''}` }}
                </template>
              </v-combobox>
              <v-address-field
                v-if="isThailand"
                v-model="editData.postcode"
                label="Postcode"
                color="secondary"
                type="postcode"
                class="shipping-address-field"
                dense
                @select="onSelectAddress($event)" />
              <v-combobox
                v-else-if="isHavePostalCode"
                v-model="editData.postcode"
                :search-input.sync="searchPostalCode"
                label="Postcode"
                color="secondary"
                :items="locations"
                item-text="postalCode"
                item-value="postalCode"
                :rules="[
                  (v) => !!v && !isError.postcode || 'Field is require.'
                ]"
                :loading="loading"
                dense
                class="shipping-address-field"
                @input="onSelectLocation($event, false)">
                <template #item="{ item }">
                  {{ `${item.postalCode} ${item.city}` }}
                </template>
              </v-combobox>
            </div>
          </div>
        </v-form>

        <div class="d-flex justify-center align-center py-2">
          <v-btn
            class="mr-3"
            color="secondary"
            small
            @click="save()">
            <v-icon x-small>
              mdi-content-save-outline
            </v-icon>
            <span class="ml-1">
              Save
            </span>
          </v-btn>

          <v-btn
            small
            @click="toggleEditAddress()">
            <span>
              Cancel
            </span>
          </v-btn>
        </div>
      </template>
      <div
        v-else
        class="shipping-address-box-edit">
        <p>
          <span>Name :</span>
          <span>{{ fullname }}</span>
        </p>
        <p>
          <span>Tel :</span>
          <span>{{ shippingAddress.contactNo || '-' }}</span>
        </p>
        <p>
          <span>Email :</span>
          <span>{{ shippingAddress.email || '-' }}</span>
        </p>
        <p>
          <span>Tax ID :</span>
          <span>{{ shippingAddress.taxId || '-' }}</span>
        </p>
        <p>
          <span>Address :</span>
          <span>
            {{ mapAddress }}
          </span>
        </p>
        <p>
          <span>Note :</span>
          <span>
            {{ shippingAddress.note || '-' }}
          </span>
        </p>
      </div>
    </v-card>
  </div>
</template>

<script>
import capitalize from 'lodash/capitalize'
import { mapGetters, mapActions } from 'vuex'
import ShippingCountries from '@/assets/json/ShippingCountries.json'
import OrderProvider from '@/resources/OrderProvider'
import MemberProvider from '@/resources/MemberProvider'
import DHLProvider from '@/resources/DHLProvider'
import VAddressField from '@/components/form/VAddressField.vue'
import AddressBox from './AddressBox.vue'

const MemberService = new MemberProvider()
const OrderService = new OrderProvider()
const DHLServices = new DHLProvider()

export default {
  components: {
    AddressBox,
    VAddressField
  },
  props: {
    isEditAddress: {
      type: Boolean,
      default: false
    },
    inputDisabled: {
      type: Boolean,
      default: false
    },
    memberId: {
      type: Number,
      default: null
    },
    heightOffset: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      loading: false,
      useDefaultAddress: false,
      shippingCountries: ShippingCountries,
      editData: {
        firstName: null,
        lastName: null,
        contactNo: null,
        email: null,
        taxId: null,
        address: null,
        address2: null,
        subDistrict: null,
        district: null,
        stateProvince: null,
        postcode: null,
        country: 'Thailand'
      },
      checkTimeout: null,
      checkDHLTimeout: null,
      locations: [],
      isError: {
        province: false,
        postcode: false
      },
      searchProvince: '',
      searchPostalCode: ''
    }
  },
  computed: {
    ...mapGetters({
      orderDetail: 'Order/orderDetail',
      editMode: 'Order/onEditMode',
      isMainOrder: 'Order/isMainOrder',
      shipping: 'Order/shipping',
      currencyUnit: 'Order/currencyUnit'
    }),
    isThailand () {
      return this.editData.country?.toUpperCase() === 'THAILAND'
    },
    member () {
      return this.orderDetail?.member || {}
    },
    fullname () {
      const keys = ['title', 'firstName', 'lastName']
      const name = this.mapShippingAddressByKey(keys)
      return name.trim() || '-'
    },
    shippingAddress () {
      return this.orderDetail.shippingAddress
    },
    editAddress: {
      get () {
        return this.editData
      },
      set (val) {
        this.editData = {
          ...val,
          subDistrict: val.states,
          stateProvince: val.province
        }

        this.setOrderDetail({ ...this.orderDetail, shippingAddress: val })
      }
    },
    mapAddress () {
      const keys = [
        'address',
        'address2',
        'subDistrict',
        'district',
        'stateProvince',
        'postcode',
        'country'
      ]
      const address = this.mapShippingAddressByKey(keys)
      return address.trim() || '-'
    },
    isHavePostalCode () {
      if (!this.locations.length) {
        return true
      }

      return this.locations.some((v) => v.postalCode)
    },
    postalCodePass () {
      return (this.isHavePostalCode && this.editData.stateProvince && this.editData.postcode)
        || (!this.isHavePostalCode && this.editData.stateProvince)
    }
  },
  watch: {
    shippingAddress (val) {
      if (val?.country) {
        this.searchProvince = val.stateProvince || ''
        this.searchPostalCode = val.postcode || ''
        this.editData = { ...val }
      }
    },
    'editData.country': {
      handler () {
        if (
          this.shipping.method === 'dhl_express'
          && this.isEditAddress
          && this.postalCodePass
        ) {
          clearTimeout(this.checkDHLTimeout)
          this.checkDHLTimeout = setTimeout(() => this.checkDHLRate(), 500)
        } else if (this.shipping.method === 'dhl_express' && this.isEditAddress) {
          this.addressCheck()
        }
      },
      deep: true
    },
    'editData.stateProvince': {
      handler () {
        if (
          this.shipping.method === 'dhl_express'
          && this.isEditAddress
          && this.postalCodePass
        ) {
          clearTimeout(this.checkDHLTimeout)
          this.checkDHLTimeout = setTimeout(() => this.checkDHLRate(), 500)
        }
      },
      deep: true
    },
    'editData.postcode': {
      handler () {
        if (
          this.shipping.method === 'dhl_express'
          && this.isEditAddress
          && this.postalCodePass
        ) {
          clearTimeout(this.checkDHLTimeout)
          this.checkDHLTimeout = setTimeout(() => this.checkDHLRate(), 500)
        }
      },
      deep: true
    },
    searchProvince () {
      clearTimeout(this.checkTimeout)
      this.checkTimeout = setTimeout(() => this.addressCheck(), 500)
    },
    searchPostalCode () {
      clearTimeout(this.checkTimeout)
      this.checkTimeout = setTimeout(() => this.addressCheck(), 500)
    }
  },
  mounted () {
    if (this.shippingAddress?.country) {
      this.searchProvince = this.shippingAddress.stateProvince || ''
      this.searchPostalCode = this.shippingAddress.postcode || ''
      this.editData = { ...this.shippingAddress }
    }
  },
  methods: {
    ...mapActions({
      setShippingFee: 'Order/setShippingFee',
      setOrderDetailDHLPackages: 'Order/setOrderDetailDHLPackages',
      setOrderDetail: 'Order/setOrderDetail',
      setBackupOrderDetail: 'Order/setBackupOrderDetail',
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading'
    }),
    async addressCheck () {
      this.isError = {
        province: false,
        postcode: false
      }

      const foundCountry = this.shippingCountries.find((c) => c.country.toLowerCase() === this.editData?.country?.toLowerCase())

      if (!foundCountry) {
        throw Error('Country or postal code was wrong.')
      }

      await this.onValidate()

      if (foundCountry.code.toUpperCase() === 'TH') {
        return
      }

      try {
        this.loading = true

        const { data } = await DHLServices.postalCodeCheck({
          countryCode: foundCountry.code,
          city: this.searchProvince || '',
          postalCode: this.searchPostalCode || ''
        })

        this.locations = data.locations

        if (!data.isMatch) {
          this.isError = {
            province: data.errorField === 'city',
            postcode: data.errorField === 'postalCode'
          }
        }

        await this.onValidate()
      } catch (error) {
        console.error('addressCheck', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async checkDHLRate () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'Getting Rates...'
        })

        const address = this.mapAddressInputFromDefaultToManualWebFormat(this.editData)
        const foundCountry = this.shippingCountries.find((c) => c.country.toLowerCase() === address.country.toLowerCase())

        if (!foundCountry) {
          throw Error('Country or postal code was wrong.')
        }

        const skus = this.orderDetail.skus.map((sku) => ({
          id: sku.id,
          productId: sku.productId,
          amount: sku.amount,
          tags: sku.tags
        }))

        const { data } = await DHLServices.getRates({
          countryCode: foundCountry.code,
          cityName: address.stateProvince,
          postalCode: address.postcode || '',
          plannedShippingDate: this.$dayjs().add(1, 'd').format('YYYY-MM-DD'),
          skus
        })

        if (this.currencyUnit === 'THB') {
          this.setShippingFee(data.price.thb)
        } else {
          this.setShippingFee(data.price.usd)
        }

        this.setOrderDetailDHLPackages(data?.namedPackages || [])
      } catch (error) {
        console.error('checkDHLRate', error)
        this.setSnackbar({
          value: true,
          message: 'Country or postal code was wrong.',
          type: 'error'
        })
        throw error
      } finally {
        this.setLoading({ active: false })
      }
    },
    mapAddressInputFromDefaultToManualWebFormat (address) {
      return {
        ...address,
        states: address.subDistrict || '',
        province: address.stateProvince || ''
      }
    },
    toggleAddressMode (value) {
      this.useDefaultAddress = value
    },
    onSelectAddress (address) {
      this.editData.subDistrict = address?.subDistrict || ''
      this.editData.district = address?.district || ''
      this.editData.stateProvince = address?.province || ''
      this.editData.postcode = address?.postcode || ''
    },
    onSelectDefaultAddress (address) {
      this.editAddress = address
    },
    onSelectLocation (val, isCity = false) {
      if (!val) {
        this.searchProvice = isCity ? '' : this.searchProvice
        this.searchPostalCode = isCity ? this.searchPostalCode : ''

        return
      }

      this.searchProvice = val.city
      this.searchPostalCode = val?.postalCode || ''
      this.editData.stateProvince = val.city
      this.editData.postcode = val?.postalCode || ''
    },
    mapShippingAddressByKey (keys) {
      const newArr = []
      keys.forEach((key) => {
        if (this.shippingAddress[key]) {
          newArr.push(this.shippingAddress[key])
        }
      })
      return newArr.join(' ')
    },
    toggleEditAddress () {
      this.$emit('update:isEditAddress', !this.isEditAddress)
    },
    shippingAddressValidate (shippingAddress) {
      const validFields = [
        'firstName',
        'lastName',
        'contactNo',
        'address',
        'subDistrict',
        'district',
        'stateProvince',
        'country'
      ]

      if (shippingAddress.country.toUpperCase() === 'INDONESIA') {
        validFields.push('taxId')
      } else if (this.isHavePostalCode) {
        validFields.push('postcode')
      }

      if (this.orderDetail.channel !== 'celeb') {
        validFields.push('email')
      }

      let invalidField = null
      for (const field of validFields) {
        if (!shippingAddress[field]) {
          invalidField = field
          break
        }
      }

      return invalidField
    },
    async save () {
      try {
        const address = this.mapAddressInputFromDefaultToManualWebFormat(this.editData)
        const invalidate = this.shippingAddressValidate(address)

        if (invalidate) {
          this.setSnackbar({
            value: true,
            message: `Missing ${capitalize(invalidate)} field.`,
            type: 'error'
          })
        } else {
          if (this.shipping.method === 'dhl_express') {
            await this.checkDHLRate()
          }

          this.setOrderDetail({ ...this.orderDetail, shippingAddress: address })

          if (this.orderDetail.orderId) {
            await OrderService.editShippingAddress(this.orderDetail.orderId, address)
          }

          this.setSnackbar({
            value: true,
            message: 'Addresses saved',
            type: 'success'
          })

          this.toggleEditAddress()
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[SAVE-ADDRESS-ERROR]: ${error.message}`,
          type: 'error'
        })
      }
    },
    async editDefaultAddress (addresses) {
      try {
        await MemberService.editAddressesById(this.memberId, addresses)

        this.setSnackbar({
          value: true,
          message: 'Edit default addresses success',
          type: 'success'
        })
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[EDIT-DEFAULT-ADDRESSES-ERROR]: ${error.message}`,
          type: 'error'
        })

        console.error(`[EDIT-DEFAULT-ADDRESSES-ERROR]: ${error}`)
      }
    },
    async addDefaultAddress () {
      try {
        const address = this.mapAddressInputFromDefaultToManualWebFormat(this.editData)
        const invalidate = this.shippingAddressValidate(address)

        if (!invalidate) {
          this.setOrderDetail({ ...this.orderDetail, shippingAddress: address })
          const { data: { addresses } } = await MemberService.getMemberById(this.memberId)

          addresses.push(address)
          await MemberService.editAddressesById(this.memberId, addresses)

          this.toggleAddressMode(false)

          this.setSnackbar({
            value: true,
            message: 'Add default address success',
            type: 'success'
          })
        } else {
          this.setSnackbar({
            value: true,
            message: `Missing ${capitalize(invalidate)} field.`,
            type: 'error'
          })
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[SAVE-DEFAULT-ADDRESS-ERROR]: ${error.message}`,
          type: 'error'
        })

        console.error(`[SAVE-DEFAULT-ADDRESS-ERROR]: ${error}`)
      }
    },
    async onValidate () {
      if (this.isEditAddress) {
        await this.$refs['address-form'].validate()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.order-address {
  width: 100%;
  height: 100%;
}
.shipping-address-box-edit {
  padding: 10px;
  background-color: #f0f0f0;
}
.shipping-address-box-edit p {
  margin-bottom: 0;
  font-size: 12px;
  color: #000;
}
.shipping-address-box-edit span:first-child {
  margin-right: 5px;
  font-weight: bold;
}
</style>
