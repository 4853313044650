<template>
  <v-card flat>
    <div
      v-for="(orderPackage, index) in orderDHLPackages"
      :key="`package-box-${index + 1}`"
      class="payment-box"
      :data-count="(index + 1)">
      <p>
        <span>Name :</span>
        <span>{{ orderPackage.name }}</span>
      </p>
      <p>
        <span>Weight (kg) :</span>
        <span>{{ orderPackage.weight }}</span>
      </p>
      <p>
        <span>Dimensions (cm) :</span>
        <span>{{ `${orderPackage.dimensions.width} x ${orderPackage.dimensions.length} x ${orderPackage.dimensions.height}` }}</span>
      </p>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      orderDHLPackages: 'Order/orderDHLPackages'
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
