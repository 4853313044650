import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class CurrencyProvider extends HttpRequest {
  getCurrencies (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/currency', query)
  }

  createCurrency (payload) {
    this.setHeader(getAuthToken())
    return this.post('/currency', payload)
  }

  updateCurrency (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/currency/${id}`, payload)
  }

  deleteCurrency (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/currency/${id}`)
  }
}

export default CurrencyProvider
