<template>
  <v-dialog
    v-model="modal"
    max-width="500px"
    persistent>
    <v-card>
      <validation-observer
        ref="form"
        @submit.prevent="onConfirm()">
        <v-card-title>
          <span class="headline">
            เลือกเหตุผล
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <gw-select
                  v-model="voidReason"
                  :items="options"
                  label="เหตุผล / Reason"
                  item-text="text"
                  item-value="value"
                  :rules="{ required: true }"
                  :readonly="readonly" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="readonly"
            color="blue darken-1"
            text
            @click="onCancel()">
            Close
          </v-btn>
          <template v-else>
            <v-btn
              color="red darken-1"
              text
              @click="onCancel()">
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="onConfirm()">
              Save
            </v-btn>
          </template>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    reason: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      voidReason: '',
      options: [
        {
          value: 'DUPLICATE',
          text: 'บิลซ้ำ / Duplicate'
        },
        {
          value: 'DEFECT',
          text: 'สินค้ามีตำหนิ - คืินเงิน / Defect - Refund'
        },
        {
          value: 'STORE-CRE',
          text: 'สินค้ามีตำหนิ, ของไม่พอส่ง ให้ Store Credit / Store Credit'
        },
        {
          value: 'WRONG-TEL',
          text: 'กรอกเบอร์ลูกค้าผิด'
        },
        {
          value: 'WRONG-ITEM',
          text: 'เลืิอกสินค้าผิด'
        },
        {
          value: 'WRONG-BRAN',
          text: 'เลืิอกสาขาผิด'
        },
        {
          value: 'CHANGE-CUS',
          text: 'ลูกค้าเปลี่ยนใจ'
        },
        {
          value: 'REFUND',
          text: 'คืินเงินกรณีอื่น / Other - Refund'
        }
      ]
    }
  },
  computed: {
    modal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    reason (val) {
      if (val) {
        this.voidReason = val
      }
    }
  },
  methods: {
    async onConfirm () {
      const isValid = await this.$refs.form.validate()

      if (!isValid) {
        return
      }

      this.modal = false
      this.$emit('confirm', this.voidReason)
    },
    onCancel () {
      this.modal = false
    }
  }
}
</script>
