<template>
  <div class="celeb-wrap">
    <v-card
      flat
      class="celeb-container"
      :class="{ 'active': active }"
      @click="selectCeleb()">
      <div class="text-bold text-subtitle-2">
        {{ item.name }} {{ socialCompute(item.socialMedias) }}
      </div>
      <div class="text-bold text-body-2">
        {{ item.tel }}
      </div>
      <div class="text-caption mt-1">
        {{ getAddress(item.address) }}
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    socialCompute (socials) {
      let text = ''
      if (socials.length) {
        const instagram = socials.find((social) => social.platform === 'instagram')
        const facebook = socials.find((social) => social.platform === 'facebook')

        if (instagram) {
          text = `(ig: ${instagram.accountName})`
        } else if (facebook) {
          text = `(fb: ${facebook.accountName})`
        } else if (socials[0]) {
          text = `(${socials[0].platform}: ${socials[0].accountName})`
        }
      }

      return text
    },
    getAddress (addressObj) {
      const { address, address2, district, states, province, country, postcode } = addressObj
      let text = ''

      if (address) {
        text += `${address}`
      }

      if (address2) {
        text += ` ${address2}`
      }

      if (states) {
        text += ` ${states}`
      }

      if (district) {
        text += ` ${district}`
      }

      if (province) {
        text += ` ${province}`
      }

      if (country) {
        text += ` ${country}`
      }

      if (postcode) {
        text += ` ${postcode}`
      }

      return text
    },
    selectCeleb () {
      this.$emit('on-click', this.item)
    }
  }
}
</script>

<style scoped>
.celeb-wrap {
  position: relative;
}

.celeb-container {
  cursor: pointer;
  border: 1px solid black;
  margin: 2px;
  padding: 4px;
  min-height: 80px;
}

.celeb-container.active {
  background: gray;
  color: white;
}
</style>
