<template>
  <v-card class="px-3">
    <v-row
      class="order-list-header"
      align="center">
      <v-col
        cols="12"
        sm="6"
        md="2">
        <WarehouseSelect
          v-model="branchSelected"
          :items="userBranch"
          item-text="name"
          item-value="id"
          :menu-props="{ offsetY: true }"
          label="Branch"
          full-width
          outlined
          :disabled="isFetchingOrderList" />
      </v-col>
      <v-col
        md="2"
        sm="6"
        cols="12">
        <v-select
          v-model="channelSelected"
          :items="channel"
          :menu-props="{ offsetY: true }"
          :disabled="isFetchingOrderList"
          item-value="value"
          item-text="label"
          label="Channel"
          full-width
          auto-select-first
          outlined
          small
          hide-details
          dense
          @change="changeSelect()" />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="2">
        <v-select
          v-model="brandSelected"
          :items="allBrand"
          :menu-props="{ offsetY: true }"
          :disabled="isFetchingOrderList"
          item-value="value"
          item-text="text"
          label="Brand"
          full-width
          auto-select-first
          outlined
          hide-details
          dense
          @change="changeSelect()" />
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="12"
        md="5"
        class="d-flex align-center">
        <v-switch
          v-model="advanceSearch"
          true-value="1"
          false-value="0"
          color="secondary"
        ></v-switch>
        <v-text-field
          v-model.trim="inputSearch"
          :placeholder="advanceSearch === '0' ? 'Search by ID' : 'Search all'"
          height="38"
          dense
          outlined
          hide-details
          @keydown.enter="searchOrder()" />
        <v-btn
          class="ml-2 py-4"
          color="secondary"
          @click="searchOrder()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="orderStatus === 'paid'"
        class="d-flex justify-end"
        cols="12">
        <v-btn
          :disabled="orderSelected.length === 0 || !isMainOrder"
          color="info"
          small
          title="Ready to ship"
          @click="rtsPress()">
          RTS ({{ orderSelected.length }})
        </v-btn>
        <v-btn
          class="ml-2"
          color="info"
          small
          title="Export"
          :disabled="orderSelected.length === 0"
          @click="exportOrders()">
          Export ({{ orderSelected.length }})
        </v-btn>
      </v-col>
      <v-col
        v-else-if="orderStatus === 'ready_to_ship'"
        class="d-flex justify-end"
        cols="12">
        <v-btn
          :disabled="(orderSelected.length === 0 || isMainOrder || !isDhl)"
          color="#FFCC00"
          class="my-1"
          small
          title="DHL Order Send"
          @click="dhlProcess()">
          ส่ง DHL ({{ orderSelected.length }})
        </v-btn>
        <v-btn
          :disabled="orderSelected.length === 0 || isMainOrder || !isDhlExpress || !isNonShipment"
          color="#D2002E"
          class="my-1 mx-2 text-white"
          small
          title="DHL Express Order Send"
          @click="confirmDhlExpress()">
          ส่ง DHL Express ({{ orderSelected.length }})
        </v-btn>
        <v-btn
          :disabled="orderSelected.length === 0 || isMainOrder"
          color="info"
          class="my-1"
          small
          title="Print Label"
          @click="printClick()">
          Print Label ({{ orderSelected.length }})
        </v-btn>
        <v-btn
          class="my-1 ml-2"
          color="info"
          small
          title="Export"
          :disabled="orderSelected.length === 0"
          @click="exportOrders()">
          Export ({{ orderSelected.length }})
        </v-btn>
      </v-col>
      <v-col
        v-else-if="orderStatus === 'completed'"
        cols="12"
        class="d-flex justify-end">
        <v-btn
          :disabled="(orderSelected.length === 0 || isMainOrder || !isDhl)"
          color="#FFCC00"
          class="my-1"
          small
          title="DHL Order Send"
          @click="dhlProcess()">
          ส่ง DHL ({{ orderSelected.length }})
        </v-btn>
        <v-btn
          :disabled="orderSelected.length === 0 || isMainOrder || !isDhlExpress || !isNonShipment"
          color="#D2002E"
          class="my-1 mx-2 text-white"
          small
          title="DHL Express Order Send"
          @click="confirmDhlExpress()">
          ส่ง DHL Express ({{ orderSelected.length }})
        </v-btn>
        <v-btn
          :disabled="orderSelected.length === 0 || !isMainOrder"
          color="#E1BEE7"
          class="my-1"
          small
          @click="printOrderSummary()">
          print order summary ({{ orderSelected.length }})
        </v-btn>
        <v-btn
          class="my-1 ml-2"
          color="info"
          small
          title="Export"
          :disabled="orderSelected.length === 0"
          @click="exportOrders()">
          Export ({{ orderSelected.length }})
        </v-btn>
      </v-col>
      <!-- <div
        v-else
        class="order-list-action" /> -->
      <v-col
        v-else
        class="d-flex justify-end"
        cols="12">
        <v-btn
          color="info"
          small
          title="Export"
          :disabled="orderSelected.length === 0"
          @click="exportOrders()">
          Export ({{ orderSelected.length }})
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          ref="order-list"
          v-model="orderSelected"
          :headers="headers"
          :items="orderList"
          :items-per-page="-1"
          :loading="isFetchingOrderList"
          show-select
          item-key="orderId"
          class="elevation-1"
          fixed-header
          hide-default-footer
          height="calc(100vh - 250px)">
          <template #[`item.id`]="{ item }">
            <div class="d-flex">
              <div class="text-right mr-1">
                <div class="d-flex justify-end">
                  <span
                    class="mr-1 mt-1"
                    :class="`status-dot status-dot-${item.currentState}`" />
                  <span
                    class="order-id-text"
                    @click="openOrderDetail(item.orderId)">
                    {{ item.orderId }}
                  </span>
                </div>
                <span
                  v-if="item.lineMyShopOrderId"
                  class="myshop-id-text">
                  {{ item.lineMyShopOrderId }}
                </span>
              </div>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-if="isModifined(item)"
                    v-bind="attrs"
                    color="orange"
                    v-on="on">
                    mdi-pencil-circle-outline
                  </v-icon>
                </template>
                <span>{{ $t(`info.modifined order`) }}</span>
              </v-tooltip>
            </div>
          </template>
          <template #[`item.products`]="{ item }">
            <p
              v-for="(sku, index) in item.skus"
              :key="`${sku}-${index}`"
              class="product-text text-start">
              {{ sku.name }} ({{ sku.amount }})
            </p>
          </template>
          <template #[`item.receiver`]="{ item }">
            <p>{{ getReceiverFromShippingOrder(item.shippingAddress) }}</p>
            <p
              v-if="item.note !== ''"
              class="order-note"
            >
              NOTE : {{ item.note }}
            </p>
          </template>
          <template #[`item.price`]="{ item }">
            {{ calcNetPrice(item) | showNumberFormat() }}
          </template>
          <template #[`item.createTime`]="{ item }">
            {{ $dayjs(item.createdAt).format('DD/MM/YYYY HH:mm') }}
          </template>
          <template #[`item.paymentChannel`]="{ item }">
            {{ checkPaymentChannel(item.payments) }}
          </template>
          <template #[`item.shipment`]="{ item }">
            {{ item.shipping.refCode || '-' }}
          </template>
          <template #[`item.print`]="{ item }">
            <v-btn
              v-if="!isMainOrder"
              icon
              small
              @click="print(item.orderId)">
              <v-icon small>
                mdi-printer
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <order-detail
      :is-main-order="isMainOrder"
      @re-fetching="reFetchOrder" />
    <dhl-result
      v-if="isShowDHLResult"
      v-model="isShowDHLResult"
      :success="successData"
      :errors="errorData" />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ExportToXLSX from 'xlsx'
import OrderChannel from '@/assets/json/OrderChannel.json'
import ShippingCountries from '@/assets/json/ShippingCountries.json'
import NotSupportWY from '@/assets/json/NotSupportWY.json'
import OrderProvider from '@/resources/OrderProvider'
import StoreProvider from '@/resources/StoreProvider'
import DHLProvider from '@/resources/DHLProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import WarehouseSelect from '@/components/WarehouseSelect.vue'
import orderDetail from '../component/OrderDetail.vue'
import dhlResult from '../component/DHLResult.vue'

const ProductAttributeService = new ProductAttributeProvider()
const OrderService = new OrderProvider()
const DHLService = new DHLProvider()
const StoreService = new StoreProvider()

export default {
  components: {
    orderDetail,
    dhlResult,
    WarehouseSelect
  },
  data () {
    return {
      maxOrderSelect: 25,
      inputSearch: '',
      advanceSearch: '0',
      channel: OrderChannel,
      branchSelected: 0,
      channelSelected: null,
      brandSelected: 'all',
      page: 0,
      perPage: 25,
      totalPage: 1,
      totalOrder: 0,
      orderSelected: [],
      isShowDHLResult: false,
      successData: [],
      errorData: [],
      allBrand: []
    }
  },
  computed: {
    ...mapGetters({
      orderList: 'Order/orderList',
      isFetchingOrderList: 'Order/isFetchingOrderList',
      userBranch: 'Store/userBranch',
      statusList: 'Order/statusList',
      currencyUnit: 'Order/currencyUnit'
    }),
    orderStatus () {
      return this.$route.params.orderStatus
    },
    idQuery () {
      return this.$route.query.id ? this.$route.query.id : ''
    },
    isMainOrderQuery () {
      return this.$route.query.is_main ? this.$route.query.is_main === 'true' : false
    },
    search () {
      return this.$route.query?.search || ''
    },
    isMainOrder () {
      return this.branchSelected === 0
    },
    isDhl () {
      return this.orderSelected.every((o) => o.shipping.method !== 'dhl_express')
    },
    isDhlExpress () {
      return this.orderSelected.every((o) => o.shipping.method === 'dhl_express')
    },
    isNonShipment () {
      return this.orderSelected.every((o) => !o.shipping.refCode)
    },
    branchId () {
      return Number(this.$route.query?.branch) || 0
    },
    channelLabel () {
      return this.$route.query?.channel || 'all'
    },
    brandLabel () {
      return this.$route.query?.brand || 'all'
    },
    // selectable () {
    //   const statusAvailable = ['paid', 'ready_to_ship', 'completed']
    //   return statusAvailable.some((each) => this.orderStatus === each)
    // },
    headers () {
      const results = [
        {
          text: 'Order ID',
          sortable: false,
          value: 'id',
          width: 190
        },
        {
          text: 'Products',
          value: 'products',
          sortable: false,
          align: 'center',
          width: 300
        },
        {
          text: 'Receiver / Note',
          value: 'receiver',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Net Price',
          value: 'price',
          sortable: false,
          align: 'end'
        },
        {
          text: 'Channel',
          value: 'channel',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Create Time',
          value: 'createTime',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Shipment',
          value: 'shipment',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Print',
          value: 'print',
          sortable: false,
          align: 'center'
        }
      ]

      return results
    }
  },
  watch: {
    $route (newVal, oldVal) {
      if (newVal.name === 'OrderListByStatus' && oldVal.name === 'OrderListByStatus') {
        this.resetData()
        this.initOrderList()
        this.fetchOrder()
      }
    },
    isMainOrder (val) {
      this.setIsMainOrder(val)
    },
    branchSelected () {
      this.changeSelect()
    }
  },
  async mounted () {
    await this.getBrand()
    this.$refs['order-list'].$vnode.elm.childNodes[0].addEventListener('scroll', this.handleScroll)
    this.initOrderList()
    this.fetchOrder()
    if (this.idQuery !== '') {
      this.setIsMainOrder(this.isMainOrderQuery)
      this.openOrderDetail(this.idQuery, this.isMainOrderQuery)
    }
  },
  beforeDestroy () {
    this.$refs['order-list'].$vnode.elm.childNodes[0].removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapActions({
      fetchOrderList: 'Order/fetchOrderList',
      fetchOrderCount: 'Order/fetchOrderCount',
      selectOrderId: 'Order/selectOrderId',
      clearOrderList: 'Order/clearOrderList',
      setFetchingOrderList: 'Order/setFetchingOrderList',
      setIsMainOrder: 'Order/setIsMainOrder',
      setLoading: 'Components/setLoading',
      setModal: 'Components/setModal',
      setSnackbar: 'Components/setSnackbar'
    }),
    async getBrand () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('brands', {
          limit: 999
        })

        this.allBrand = [
        {
          text: 'All',
          value: 'all'
        },
          ...data.results.map((brand) => ({
            text: brand.name,
            value: brand.name
          }))
        ]
      } catch (error) {
        console.error('getAttribute: ', error)
      }
    },
    printOrderSummary () {
      const bills = JSON.stringify(this.orderSelected.map((or) => or.orderId))
      window.localStorage.setItem('print-order-summay', bills)
      this.$router.push({ name: 'PrintOrderSummary' })
    },
    exportOrders () {
      if (!this.orderSelected.length) {
        return
      }

      if (this.isMainOrder) {
        const exportData = this.orderSelected.map((v) => ({
          orderId: v.orderId,
          channel: v.channel,
          currentState: v.currentState,
          itemQty: v.skus.reduce((sum, s) => sum + s.amount, 0),
          shippingFee: v.shipping.fee,
          net: v.net,
          note: v.note
        }))

        this.exportDHLToXlsx(exportData, 'order-list')
      } else {
        const exportData = this.orderSelected.map((v) => ({
          orderId: v.orderId,
          warehouseCode: v.warehouse.code,
          channel: v.channel,
          currentState: v.currentState,
          itemQty: v.skus.reduce((sum, s) => sum + s.amount, 0),
          shippingFee: v.shipping.fee,
          promotionCode: v.promotionCode?.code || '',
          discount: v.discount,
          net: v.net,
          note: v.note
        }))

        this.exportDHLToXlsx(exportData, 'order-list')
      }
    },
    calcNetPrice (item) {
      return item.net || 0
    },
    isModifined (order) {
      return this.isMainOrder && order.regenFrom
    },
    async reFetchOrder () {
      this.resetData()
      this.fetchOrder()
    },
    async fetchOrder () {
      try {
        this.setFetchingOrderList(true)
        if (this.page < this.totalPage) {
          this.page++

          let currentState = null

          if (this.orderStatus !== 'deleted') {
            currentState = this.orderStatus !== 'all' ? this.orderStatus : null
          }

          let payload = {
            isMainOrder: this.isMainOrder,
            currentState,
            channel: this.channelSelected.toLowerCase(),
            warehouse: this.branchSelected,
            brand: this.brandSelected,
            page: this.page,
            perPage: this.perPage,
            search: this.search,
            advanceSearch: this.advanceSearch,
            status: this.orderStatus !== 'deleted' ? 'active' : 'deleted'
          }

          if (currentState === 'void' || currentState === 'exchange_return') {
            payload = { ...payload, sortBy: ['updatedAt'], sortDesc: 'desc' }
          } else if (this.sortOrderAsc()) {
            payload = { ...payload, sortBy: ['createdAt'], sortDesc: 'asc' }
          }

          this.fetchOrderCountMethod()
          await this.fetchOrderList(payload)
        }
      } catch (error) {
        console.error('fetchOrder', error)
      } finally {
        this.setFetchingOrderList(false)
      }
    },
    async fetchOrderCountMethod () {
      await this.fetchOrderCount({
        search: this.search,
        warehouseId: this.branchSelected,
        brand: this.brandSelected,
        channel: this.channelSelected.toLowerCase(),
        advanceSearch: this.advanceSearch
      })

      const total = this.statusList[this.orderStatus]?.count || 0
      const pages = Math.ceil(total / this.perPage)
      this.totalPage = pages
      this.totalOrder = total
    },
    getReceiverFromShippingOrder (shippingAddress) {
      let text = ''
      const { firstName, lastName, note } = shippingAddress

      if (firstName) {
        text += `${firstName} `
      }

      if (lastName) {
        text += `${lastName}`
      }

      if (note) {
        text = note
      }

      return text || ''
    },
    sortOrderAsc () {
      const dateAsc = ['paid', 'ready_to_ship']
      const asc = dateAsc.some((status) => status === this.orderStatus)

      return asc
    },
    initOrderList () {
      this.branchSelected = this.branchId
      this.channelSelected = this.channelLabel
      this.brandSelected = this.brandLabel
      this.inputSearch = this.search
      this.clearSelected()
    },
    handleScroll (event) {
      const e = event.target
      const scrollY = e.scrollHeight - e.clientHeight - 200
      if (e.scrollTop >= scrollY && !this.isFetchingOrderList) {
        this.fetchOrder()
      }
    },
    clearSelected () {
      this.orderSelected = []
    },
    changeSelect () {
      this.clearSelected()
      this.setRouterQuery()
      this.resetData()
      this.reFetchOrder()
    },
    resetData () {
      this.clearOrderList()
      this.page = 0
      this.totalPage = 1
      this.totalOrder = 0
    },
    setRouterQuery () {
      const query = {
        branch: this.branchSelected || null,
        channel: this.channelSelected || null,
        brand: this.brandSelected || null,
        search: this.inputSearch || ''
      }
      for (const key of Object.keys(query)) {
        if (!query[key]) {
          delete query[key]
        }
      }
      this.$router.push({
        name: this.$route.name,
        query
      }, () => {})
    },
    checkPaymentChannel (payments) {
      if (Array.isArray(payments)) {
        return payments[0]?.channel || '-'
      }
      return '-'
    },
    searchOrder () {
      this.changeSelect()
    },
    openOrderDetail (orderId, isMain = true) {
      this.selectOrderId({
        id: orderId,
        isMainOrder: isMain ? this.isMainOrder : false
      })
    },
    print (orderId) {
      this.setPrintList([orderId])
      const routeData = this.$router.resolve({ name: 'OrderPrintLabel' })
      window.open(routeData.href, '_blank')
    },
    printClick () {
      this.setPrintList(this.orderSelected.map((order) => order.orderId))
      const routeData = this.$router.resolve({ name: 'OrderPrintLabel' })
      window.open(routeData.href, '_blank')
    },
    setPrintList (payload) {
      window.localStorage.removeItem('print-list')
      window.localStorage.setItem('print-list', JSON.stringify(payload))
    },
    async getDhlPickupId () {
      const { data: { dhlEcommerceSetting: { pickupWarehouses, dhlPrefix, filename } } } = await StoreService.getStoreSetting()
      return { pickupWarehouses, dhlPrefix, filename }
    },
    async dhlProcess () {
      try {
        const { pickupWarehouses, dhlPrefix, filename } = await this.getDhlPickupId()
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'Creating shipment...'
        })

        const bills = JSON.parse(JSON.stringify(this.orderSelected))

        const payload = []
        const data = bills.map((row) => {
          const foundpickupId = pickupWarehouses.find((warehouse) => warehouse.id === row.warehouse.id)
          const pickupId = foundpickupId?.pickupId || null
          const orderPrefix = row.orderId.substring(0, 2)
          const orderId = row.orderId.replace('-', '')
          const cutDigit = orderId.substring(2)
          let increaseDigit = 1

          if (row.shipping.refCode) {
            const prefix = row.shipping.refCode.substring(0, 8)

            if (prefix === `${dhlPrefix}${orderPrefix}D`) {
              const postfix = row.shipping.refCode.substring(row.shipping.refCode.length - 2)
              increaseDigit = Number(postfix) + 1
            }
          }

          increaseDigit = increaseDigit.toString().padStart(2, 0)

          const trackNumber = `${orderPrefix}D${cutDigit}${increaseDigit}`

          payload.push({
            orderId: row.orderId,
            providerName: 'DHL',
            method: 'pdo',
            refCode: `${dhlPrefix}${trackNumber}`
          })

          const address = `${row.shippingAddress.address} ${row.shippingAddress.address2 || ''} ${row.shippingAddress.subDistrict}`
          const address1 = `${address.slice(0, 55)}`
          const address2 = `-${address.slice(55, 110)}`
          const address3 = `-${address.slice(110, 165)}`

          const tmpData = {
            'เลขบัญชีที่รับสินค้า': pickupId,
            'ช่องทางการขาย': '',
            'รหัสการจัดส่ง': trackNumber,
            'รหัสบริการจัดส่ง': 'PDO',
            'บริษัท': '',
            'ชื่อผู้รับ': `${row.shippingAddress.firstName} ${row.shippingAddress.lastName}`,
            'ที่อยู่บรรทัดที่ 1': address1,
            'ที่อยู่บรรทัดที่ 2': address2,
            'ที่อยู่บรรทัดที่ 3': address3,
            'เขต (อำเภอ)': row.shippingAddress.district,
            'จังหวัด': row.shippingAddress.stateProvince,
            'รหัสไปรษณีย์': row.shippingAddress.postcode,
            'รหัสประเทศปลายทาง': 'TH',
            'หมายเลขโทรศัพท์': row.shippingAddress.contactNo,
            'อีเมล์์': row.shippingAddress.email,
            'น้ำหนักสินค้า (กรัม)': '100',
            'ความยาว (ซม)': '',
            'ความกว้าง (ซม)': '',
            'ความสูง (ซม)': '',
            'สกุลเงิน': 'THB',
            'ยอดรวมมูลค่าสินค้า': '',
            'ประกัน': '',
            'มูลค่าการทำประกัน': '',
            'เก็บเงินปลายทาง': '',
            'มูลค่าการเก็บเงินปลายทาง': '',
            'รายละเอียดการจัดส่ง': '',
            'หมายเหตุ': '',
            'ชื่อบริษัทผู้จัดส่ง': '',
            'ชื่อผู้จัดส่ง': '',
            'ที่อยู่ผู้จัดส่งบรรทัดที่ 1': '',
            'ที่อยู่ผู้จัดส่งบรรทัดที่ 2': '',
            'ที่อยู่ผู้จัดส่งบรรทัดที่ 3': '',
            'เขต (อำเภอ) ผู้จัดส่ง': '',
            'จังหวัดผู้จัดส่ง': '',
            'รหัสไปรษณีย์ผู้จัดส่ง': '',
            'รหัสประเทศปลายทางผู้จัดส่ง': '',
            'หมายเลขโทรศัพท์ผู้จัดส่ง': '',
            'อีเมล์ผู้จัดส่ง': '',
            'รหัสบริการส่งคืนสินค้า': '',
            'ชื่อบริษัทที่ส่งคืน': '',
            'ชื่อที่ส่งคืน': '',
            'ที่อยู่ที่ส่งคืนบรรทัดที่ 1': '',
            'ที่อยู่ที่ส่งคืนบรรทัดที่ 2': '',
            'ที่อยู่ที่ส่งคืนบรรทัดที่ 3': '',
            'เขต (อำเภอ) ที่ส่งคืน': '',
            'จังหวัดที่ส่งคืน': '',
            'รหัสไปรษณีย์ที่ส่งคืน': '',
            'รหัสประเทศปลายทางที่ส่งคืน': '',
            'หมายเลขโทรศัพท์ที่ส่งคืน': '',
            'อีเมล์ที่ส่งคืน': '',
            'บริการ 1': '',
            'บริการ 2': '',
            'กระบวนการ Handover': '',
            'โหมดการส่งคืนของ': '',
            'การอ้างอิงการเรียกเก็บเงิน 1': '',
            'การอ้างอิงการเรียกเก็บเงิน 2': '',
            'IsMult': '',
            'ตัวเลือกการจัดส่ง': '',
            'รหัสชิ้น': '',
            'คำอธิบายรายชิ้น': '',
            'น้ำหนักรายชิ้น': '',
            'การเรียกเก็บเงินปลายทางรายชิ้น': '',
            'มูลค่าการทำประกันรายชิ้น': '',
            'การอ้างอิงการเรียกเก็บเงินรายชิ้น 1': '',
            'การอ้างอิงการเรียกเก็บเงินรายชิ้น 2': ''
          }

          return tmpData
        })

        await this.addTrackingNo(payload, data, filename)
      } catch (error) {
        console.error('dhlProcess', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async confirmDhlExpress () {
      if (this.orderSelected.some((order) => !!order.shipping.refCode)) {
        const orderIds = this.orderSelected
          .filter((order) => !!order.shipping.refCode)
          .map((order) => order.orderId)

        this.setModal({
          value: true,
          title: 'ส่ง DHL Express',
          message: `ออเดอร์\n${orderIds.join('\n')}\nมีการส่ง DHL Express ไปแล้ว ต้องการส่งอีกครั้งหรือไม่?`,
          confirmText: 'ใช่',
          confirmType: 'error',
          cancelType: '',
          cancelText: 'ไม่ใช่',
          onConfirm: () => this.dhlExpressProcess()
        })
      } else {
        this.dhlExpressProcess()
      }
    },
    async dhlExpressProcess () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'Creating shipment...'
        })

        for await (const order of this.orderSelected) {
          const foundCountry = ShippingCountries.find((c) => c.country.toLowerCase() === order.shippingAddress.country.toLowerCase())

          if (!foundCountry) {
            throw Error('Country or postal code was wrong.')
          }

          const { data } = await DHLService.createShipment({
            orderId: order.orderId,
            receiverCountryCode: foundCountry.code
          })

          if (NotSupportWY.includes(foundCountry.code)) {
            data.documents.forEach((document) => {
              this.downloadPDF(document.content, `${order.orderId}-${document.typeCode}`)
            })
          } else {
            const document = data.documents.find((doc) => doc.typeCode === 'label')

            if (document) {
              this.downloadPDF(document.content, `${order.orderId}-${document.typeCode}`)
            }
          }

          await OrderService.updateTrackingOrder(order.orderId, data.shipmentTrackingNumber)
        }

        this.setSnackbar({
          value: true,
          message: 'Created shipment successfully.',
          type: 'success'
        })

        this.reFetchOrder()
      } catch (error) {
        console.error('dhlExpressProcess', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async addTrackingNo (payload, data, filename) {
      this.setLoading({
        active: true,
        clickAble: false,
        message: 'Updating order...'
      })

      const exportData = []
      this.successData = []
      this.errorData = []

      for await (const [index, item] of payload.entries()) {
        try {
          await OrderService.updateTrackingOrderDHL(item)
          this.successData.push(item.orderId)
          exportData.push(data[index])
        } catch (error) {
          this.errorData.push({
            code: error.code,
            orderId: item.orderId,
            message: error.message
          })
          console.error('addTrackingNo', error)
        }
      }

      this.setLoading({ active: false })
      this.orderSelected = []
      this.reFetchOrder()
      this.isShowDHLResult = true

      if (exportData.length) {
        this.exportDHLToXlsx(exportData, filename)
      }
    },
    exportDHLToXlsx (data, fileName) {
      const workSheet = ExportToXLSX.utils.json_to_sheet(data)
      const newWorkbook = ExportToXLSX.utils.book_new()
      ExportToXLSX.utils.book_append_sheet(newWorkbook, workSheet, 'Sheet1')
      ExportToXLSX.writeFile(newWorkbook, `${fileName}.xlsx`)
    },
    downloadPDF (pdf, fileName) {
      const linkSource = `data:application/pdf;base64,${pdf}`
      const downloadLink = document.createElement('a')

      downloadLink.href = linkSource
      downloadLink.download = `${fileName}.pdf`
      downloadLink.click()
    },
    async rtsPress () {
      this.setLoading({
        active: true,
        clickAble: false,
        message: 'CHANGE STATUS TO READY TO SHIP...'
      })
      try {
        if (this.orderSelected.length > this.maxOrderSelect) {
          this.setSnackbar({
            value: true,
            message: `Orders maximum selects at ${this.maxOrderSelect} orders`,
            type: 'error'
          })

          this.orderSelected = this.orderSelected.slice(0, this.maxOrderSelect)

          return
        }

        const { hasError } = await this.splitReadyToShip(this.orderSelected)
        if (!hasError) {
          this.setSnackbar({
            value: true,
            message: 'All orders is ready to ship',
            type: 'success'
          })
        } else {
          this.setSnackbar({
            value: true,
            message: 'Ready to ship is completed, but some order can\'t',
            type: 'error'
          })
        }
        this.orderSelected = []
        this.reFetchOrder()
      } catch (error) {
        console.error(error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async splitReadyToShip (orders) {
      let hasError = false
      const config = {
        exceed: 5
      }
      const queue = orders
      const cycles = Math.ceil(queue.length / config.exceed)

      for (let cycle = 1; cycle <= cycles; cycle++) {
        try {
          const current = queue.splice(0, config.exceed)
          const unpromised = current.map((each) => this.fetchOrderDetail(each.orderId))
          // eslint-disable-next-line no-await-in-loop
          const promised = await Promise.all(unpromised)
          // eslint-disable-next-line no-await-in-loop
          await Promise.all(promised.map((each) => this.readyToShip(each)))
        } catch {
          hasError = true
          this.setSnackbar({
            value: true,
            message: `some order is error on preparing to ship at stage ${cycle}/${cycles}`,
            type: 'error'
          })
        }
      }

      return { hasError }
    },
    readyToShip (payload) {
      const { orderId } = payload
      return OrderService.updateStatusToReadyToShip(orderId)
    },
    async fetchOrderDetail (id) {
      let fetchData = null
      try {
        const { data } = await OrderService.getOrderById(id)
        fetchData = data
      } catch (error) {
        console.error('fetchOrderDetail', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      }

      return fetchData
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/gw-variables.scss';
#order-list-body {
  height: calc(100vh - 220px);
  overflow: auto;
}
.order-id {
  display: flex;
  justify-content: flex-start;
}
.order-list-action {
  height: 64px;
}
span.myshop-id-text {
  color: #18a352;
}
span.order-id-text {
  cursor: pointer;
  text-decoration: underline;
}
.product-text {
  font-size: 12px;
}
.text-white {
  color: white;
}
.order-note {
  font-size: 12px !important;
  color: rgb(255, 94, 161);
}
span.status-dot {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: $bill-status;
  }
span.status-dot.status-dot-pending {
  background-color: $bill-status-pending;
}
span.status-dot.status-dot-reserved {
  background-color: $bill-status-reserved;
}
span.status-dot.status-dot-paid {
  background-color: $bill-status-paid;
}
span.status-dot.status-dot-waiting {
  background-color: $bill-status-waiting;
}
span.status-dot.status-dot-ready_to_ship {
  background-color: $bill-status-readytoship;
}
span.status-dot.status-dot-completed {
  background-color: $bill-status-completed;
}
span.status-dot.status-dot-void {
  background-color: $bill-status-void;
}
span.status-dot.status-dot-exchange_return {
  background-color: $bill-status-exchange_return;
}
span.status-dot.status-dot-expired {
  background-color: $bill-status-expired;
}
</style>
