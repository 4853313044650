<template>
  <div class="address-inputs">
    <div
      v-for="(address, index) in addresses"
      :key="`address-card-index-${index}`"
      class="address-card-wrap ">
      <v-card
        flat
        class="address-card"
        :class="{ 'active': index === selectedAddress.index }"
        @click="selectAddress(address, index)">
        <div class="text-bold text-subtitle-2">
          {{ `${address.firstName} ${address.lastName}` }}
        </div>
        <div class="text-bold text-body-2">
          {{ `${address.contactNo}` }}
        </div>
        <div class="text-caption mt-1">
          {{ `${getAddress(address)}, ${getCountryAndPostCode(address)} ` }}
        </div>
      </v-card>
      <v-btn
        icon
        :color="index === selectedAddress.index ? 'white' : 'error'"
        class="close-button"
        @click="removeAddress(index)">
        <v-icon>
          mdi-minus-circle-outline
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import MemberProvider from '@/resources/MemberProvider'

const MemberService = new MemberProvider()

export default {
  props: {
    member: {
      type: Object,
      default: () => ({})
    },
    active: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      addresses: [],
      selectedAddress: {
        index: -1
      }
    }
  },
  watch: {
    member: {
      handler (value) {
        this.getMember(value.id)
      },
      deep: true
    },
    active: {
      handler (value) {
        if (value && this.member) {
          this.getMember(this.member.id)
        }
      },
      deep: true
    }
  },
  methods: {
    async getMember (id) {
      try {
        if (!id) {
          this.addresses = []
          return
        }

        const { data } = await MemberService.getMemberById(id)

        this.addresses = data?.addresses || []
      } catch (error) {
        console.error(`ERROR: ${error.message}`)
      }
    },
    selectAddress (address, index) {
      this.selectedAddress = {
        ...address,
        index
      }

      this.$emit('select', address)
    },
    removeAddress (index) {
      this.addresses.splice(index, 1)

      this.$emit('edited', this.addresses)
    },
    getCountryAndPostCode (addressObj) {
      const { country, postcode } = addressObj
      let text = ''

      if (country) {
        text += ` ${country}`
      }

      if (postcode) {
        text += ` ${postcode}`
      }

      return text
    },
    close () {
      this.$emit('close')
    },
    getAddress (addressObj) {
      const { address, address2, district, states, province } = addressObj
      let text = ''

      if (address) {
        text += `${address}`
      }

      if (address2) {
        text += ` ${address2}`
      }

      if (states) {
        text += ` ${states}`
      }

      if (district) {
        text += ` ${district}`
      }

      if (province) {
        text += ` ${province}`
      }

      return text
    }
  }
}
</script>

<style scoped>
  .close-button {
    z-index: 999;
    position: absolute;
    top: 0;
    right: 0;
  }

  .address-card {
    cursor: pointer;
    border: 1px solid black;
    margin: 2px;
    padding: 4px;
  }

  .address-card-wrap {
    position: relative;
  }

  .address-card.active {
    border: 1px solid black;
    background: gray;
    color: white;
  }
</style>
