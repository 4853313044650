<template>
  <v-card flat>
    <div
      v-for="payment in orderPayments"
      :key="`payment-box-${payment.id}`"
      class="payment-box"
      :data-count="payment.payOrder">
      <p>
        <span>Pay Amount :</span>
        <span>{{ payment.payAmount | showFullPriceFormat() }} {{ currencyUnit }}</span>
      </p>
      <p>
        <span>Debt. Amount :</span>
        <span>{{ payment.debtAmount | showFullPriceFormat() }} {{ currencyUnit }}</span>
      </p>
      <p>
        <span>Status :</span>
        <span>{{ checkStatusPayment(payment.paidDate) }}</span>
      </p>
      <p>
        <span>Transaction Date :</span>
        <span>{{ transactionDate(payment.paidDate) }}</span>
      </p>
      <p>
        <span>Payment Channel :</span>
        <span>{{ payment.channel || '-' }}</span>
      </p>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      orderPayments: 'Order/orderPayments',
      currencyUnit: 'Order/currencyUnit'
    })
  },
  methods: {
    checkStatusPayment (date) {
      return date ? 'Paid' : 'Waiting'
    },
    transactionDate (date) {
      return date ? this.$dayjs(date).format('DD MMM YYYY HH:mm:ss') : '-'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
