<template>
  <v-col
    class="product-note"
    :cols="12">
    <v-row>
      <v-col cols="auto">
        <gw-product-image
          :src="product.photoUrls[0]"
          custom-svg="auto" />
      </v-col>
      <v-col>
        <h3>{{ product.name }} - {{ product.model }}</h3>
        <v-simple-table dense>
          <thead>
            <tr>
              <th
                v-for="(head, index) in headTable"
                :key="`head-${index}`"
                class="text-left">
                {{ head }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(sku, index) in product.skus"
              :key="`sku-${index}`">
              <td>
                <span>
                  {{ sku.code || '-' }}
                </span>
              </td>
              <td>
                <span>
                  {{ sku.color.name || '-' }}
                </span>
              </td>
              <td>
                <span>
                  {{ sku.size.name || '-' }}
                </span>
              </td>
              <td>
                <span>
                  {{ skusStock[index].onHandQty }}
                </span>
              </td>
              <td>
                <span>
                  {{ skusStock[index].onReservedQty }}
                </span>
              </td>
              <td>
                <span>
                  {{ sku.price | showFullPriceFormat() }}
                </span>
              </td>
              <td class="text-right">
                <v-btn
                  class="buttun-add-sku"
                  color="primary"
                  :disabled="isMainWarehouse"
                  x-small
                  @click="skuSelecting(sku)">
                  <v-icon x-small>
                    mdi-plus-thick
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import SizeValues from '@/assets/js/SizeValues'

export default {
  props: {
    productId: {
      type: String,
      required: true,
      default: ''
    },
    productName: {
      type: String,
      required: true,
      default: ''
    },
    model: {
      type: String,
      required: true,
      default: ''
    },
    brand: {
      type: String,
      required: true,
      default: ''
    },
    variant: {
      type: String,
      required: true,
      default: ''
    },
    skus: {
      type: Array,
      required: true,
      default: () => []
    },
    photoUrls: {
      type: Array,
      required: true,
      default: () => []
    },
    warehouseId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      headTable: [
        'Code',
        'Color',
        'Size',
        'Stock',
        'Reserved',
        'price',
        ''
      ]
    }
  },
  computed: {
    product () {
      return {
        id: this.productId,
        name: this.productName,
        model: this.model,
        brand: this.brand,
        variant: this.variant,
        skus: this.sortSize(this.skus),
        photoUrls: this.photoUrls
      }
    },
    skusStock () {
      return this.skus.map((sku) => {
        const stockByWarehouse = sku.stock.find((r) => r.warehouse.id === this.warehouseId)
        return {
          onHandQty: stockByWarehouse?.onHandQty ? stockByWarehouse.onHandQty : 0,
          onReservedQty: stockByWarehouse?.onReservedQty ? stockByWarehouse.onReservedQty : 0
        }
      })
    },
    isMainWarehouse () {
      return this.warehouseId === 0
    }
  },
  methods: {
    skuSelecting (sku) {
      this.$emit('skuSelecting', sku)
    },
    sortSize (skus) {
      return skus.sort((a, b) => SizeValues(a.size.name) - SizeValues(b.size.name))
    }
  }
}
</script>

<style lang="scss">
.product-note {
  font-size: 12px;
  th, td {
    padding: 0 4px !important;
    font-size: 11px !important;
  }
  td:first-child span {
    width: 80px;
  }
  td span {
    display: block;
    width: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  td button span {
    width: 100%;
    color: #ffffff;
  }
  .col {
    position: relative;
  }
  button.buttun-add-sku.v-btn.v-size--x-small {
    padding: 2px;
    min-width: unset;
    width: 25px;
    height: 25px;
  }
  .buttun-add-product {
    position: absolute;
    top: 6px;
    right: 12px;
    z-index: 1;
  }
  .v-data-table__wrapper {
    overflow-x: hidden;
  }
}
@media print {
  main.v-main {
    padding: 0 !important;
  }
}
</style>
