<template>
  <div>
    <v-tabs
      v-if="isMainOrder && orderDetail.status === 'active'"
      v-model="warehouseSelected">
      <v-tab
        v-for="(tab, index) in tabHeaders"
        :key="`tabs-index-${index}`">
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-if="isMainOrder && orderDetail.status === 'active'"
      v-model="warehouseSelected">
      <v-tab-item
        v-for="(tab, tabIndex) in tabHeaders"
        :key="`tab-items-index-${tabIndex}`"
        :value="tabIndex">
        <v-simple-table
          id="order-product-table"
          class="pa-0"
          :class="{ 'order-product-empty': !hasOrderSkus }"
          fixed-header
          :height="getHeightTable">
          <thead>
            <tr>
              <th
                v-for="header in tabelHeader"
                :key="header.label"
                :class="`text-${header.textAlign}`">
                {{ header.label }}
              </th>
            </tr>
          </thead>
          <tbody v-if="hasOrderSkus">
            <tr
              v-for="(sku, index) in allSubOrders[tab.id]"
              :key="sku.id">
              <td class="order-product-detail">
                <div class="order-product-detail-box">
                  <div class="order-product-image">
                    <gw-product-image :src="imgSrcCompute(sku.images[0])" />
                    <div
                      v-if="getPreOrder(sku)"
                      class="d-flex justify-center mt-1">
                      <v-chip
                        color="orange"
                        dark
                        x-small>
                        Pre-Order
                      </v-chip>
                    </div>
                  </div>
                  <div class="order-product-name">
                    <p class="mb-0 font-super-bold">
                      {{ sku.name }}
                    </p>
                    <p class="mb-0">
                      <b>Model : </b>{{ sku.code }}
                    </p>
                    <p class="mb-0">
                      <b>Color : </b>{{ sku.color }}
                    </p>
                    <p class="mb-0">
                      <b>Size : </b>{{ sku.size }}
                    </p>
                    <p
                      v-if="!isMain(tab.id) && sku.trackingNo"
                      class="mb-0">
                      <b>Tracking no. : </b>{{ sku.trackingNo }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <v-text-field
                  v-model.number="sku.amount"
                  disabled
                  class="amount-input order-summary-input"
                  type="number"
                  min="1"
                  dense
                  solo
                  outlined
                  hide-details
                  @input="calcSkuPrice({sku, index, amount: Number($event), field: 'amount'})" />
              </td>
              <td class="text-right">
                <p class="text-price">
                  {{ getUnitPrice(sku) | showFullPriceFormat() }}
                </p>
              </td>
              <td
                class="text-center"
                width="150">
                <div class="d-flex">
                  <v-text-field
                    v-model.number="sku.discountPrice"
                    :disabled="sku.mixAndMatch || inputDisabled || !editMode || !isMain(tab.id)"
                    class="discount-input order-summary-input"
                    dense
                    solo
                    outlined
                    hide-details
                    @keypress="$keypressNumber($event)"
                    @input="calcSkuPrice({sku, index})" />
                  <v-select
                    v-model="sku.discountType"
                    :items="discountType"
                    :disabled="sku.mixAndMatch || inputDisabled || !editMode || !isMain(tab.id)"
                    :menu-props="{
                      offsetY: true,
                      contentClass: 'order-summary-select-menu'
                    }"
                    item-text="label"
                    item-value="value"
                    class="discount-select order-summary-select"
                    hide-details
                    dense
                    outlined
                    @change="calcSkuPrice({sku, index, discountType: $event})">
                  </v-select>
                </div>
              </td>
              <td
                class="text-right"
                width="100">
                <p class="text-price">
                  {{ sku.price | showFullPriceFormat() }}
                </p>
              </td>
            </tr>
          </tbody>
          <tbody
            v-else
            height="100%">
            <tr class="empty-product">
              <td :colspan="6">
                <v-btn
                  :disabled="!editMode"
                  color="#ffffff"
                  icon
                  block
                  @click="openProductModal()">
                  <gw-svg-icon
                    svg-name="cart-plus-solid"
                    width="75"
                    heigth="75"
                    color="#a4a4a4" />
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-tab-item>
    </v-tabs-items>
    <v-simple-table
      v-else
      id="order-product-table"
      class="pa-0"
      :class="{ 'order-product-empty': !hasOrderSkus }"
      fixed-header
      :height="getHeightTable">
      <thead>
        <tr>
          <th
            v-for="header in tabelHeader"
            :key="header.label"
            :class="`text-${header.textAlign}`">
            {{ header.label }}
          </th>
        </tr>
      </thead>
      <tbody v-if="hasOrderSkus">
        <tr
          v-for="(sku, index) in skus"
          :key="sku.id">
          <td class="order-product-detail">
            <div class="order-product-detail-box">
              <div class="order-product-image">
                <gw-product-image :src="imgSrcCompute(sku.images[0])" />
                <div
                  v-if="getPreOrder(sku)"
                  class="d-flex justify-center mt-1">
                  <v-chip
                    color="orange"
                    dark
                    x-small>
                    Pre-Order
                  </v-chip>
                </div>
              </div>
              <div class="order-product-name">
                <p class="mb-0 font-super-bold">
                  {{ sku.name }}
                </p>
                <p class="mb-0">
                  <b>Model : </b>{{ sku.code }}
                </p>
                <p class="mb-0">
                  <b>Color : </b>{{ sku.color }}
                </p>
                <p class="mb-0">
                  <b>Size : </b>{{ sku.size }}
                </p>
                <p
                  v-if="shippingCode"
                  class="mb-0">
                  <b>Tracking no. : </b>{{ shippingCode }}
                </p>
              </div>
            </div>
          </td>
          <td>
            <v-text-field
              v-model.number="sku.amount"
              class="amount-input order-summary-input"
              disabled
              min="1"
              dense
              solo
              outlined
              hide-details />
          </td>
          <td class="text-right">
            <p class="text-price">
              {{ sku.unitPrice | showFullPriceFormat() }}
            </p>
          </td>
          <td
            class="text-center"
            width="150">
            <div class="d-flex">
              <v-text-field
                v-model.number="sku.discountPrice"
                disabled
                class="discount-input order-summary-input"
                dense
                solo
                outlined
                hide-details
                @keypress="$keypressNumber($event)"
                @input="calcSkuPrice({sku, index})" />
              <v-select
                v-model="sku.discountType"
                :items="discountType"
                disabled
                :menu-props="{
                  offsetY: true,
                  contentClass: 'order-summary-select-menu'
                }"
                item-text="label"
                item-value="value"
                class="discount-select order-summary-select"
                hide-details
                dense
                outlined>
              </v-select>
            </div>
          </td>
          <td
            class="text-right"
            width="100">
            <p class="text-price">
              {{ sku.price | showFullPriceFormat() }}
            </p>
          </td>
        </tr>
      </tbody>
      <tbody
        v-else
        height="100%">
        <tr class="empty-product">
          <td :colspan="6">
            <v-btn
              :disabled="!editMode"
              color="#ffffff"
              icon
              block
              @click="openProductModal()">
              <gw-svg-icon
                svg-name="cart-plus-solid"
                width="75"
                heigth="75"
                color="#a4a4a4" />
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-btn
      v-if="!inputDisabled && editMode"
      class="order-product-add-btn"
      :ripple="false"
      :elevation="0"
      @click="openProductModal()">
      <v-icon color="#898989">
        mdi-plus
      </v-icon>
      <span class="ml-2">
        {{ btnText }}
      </span>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    inputDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      warehouseSelected: 'main',
      tabelHeader: [
        {
          label: 'Product',
          textAlign: 'left'
        },
        {
          label: 'Amount',
          textAlign: 'center'
        },
        {
          label: 'Price',
          textAlign: 'center'
        },
        {
          label: 'Discount',
          textAlign: 'center'
        },
        {
          label: 'Net',
          textAlign: 'center'
        }
      ],
      discountType: [
        {
          label: '฿',
          value: 'amount'
        }
        // {
        //   label: '%',
        //   value: 'percent'
        // }
      ]
    }
  },
  computed: {
    ...mapGetters({
      skus: 'Order/orderSkus',
      hasOrderSkus: 'Order/hasOrderSkus',
      orderDetail: 'Order/orderDetail',
      isMainOrder: 'Order/isMainOrder',
      editMode: 'Order/onEditMode',
      allSubOrders: 'Order/allSubOrders'
    }),
    getHeightTable () {
      const isEditProduct = this.inputDisabled ? 45 : 0
      return (this.$vuetify.breakpoint.height - 405) + isEditProduct
    },
    btnText () {
      return this.hasOrderSkus ? 'Edit Product' : 'Add Product'
    },
    tabHeaders () {
      const headers = [
        { name: 'main', id: 0 }
      ]
      const subOrders = this.orderDetail?.subOrders?.map((subOrder, index) => ({
        id: `${subOrder?.warehouse.id}${index}`,
        name: subOrder?.warehouse.name
      })) || []
      return [...headers, ...subOrders]
    },
    shippingCode () {
      return this.orderDetail?.shipping?.refCode || ''
    }
  },
  watch: {
    orderDetail () {
      this.initSubOrders()
    }
  },
  mounted () {
    this.initSubOrders()
  },
  methods: {
    ...mapActions({
      calcSkuPrice: 'Order/calcSkuPrice',
      setProductModalActive: 'Order/setProductModalActive',
      setAllSubOrders: 'Order/setAllSubOrders'
    }),
    isMain (id) {
      return id === 0
    },
    isAmountDiscount (type) {
      return type === 'amount'
    },
    getUnitPrice (sku) {
      // TODO Fix this if order sg
      return sku.unitPrice || sku.fullPrice / sku.amount
    },
    initSubOrders () {
      const rawSubOrders = this.orderDetail?.subOrders || []
      const subOrders = this.mapSubOrders(rawSubOrders)
      this.setAllSubOrders(subOrders)
    },
    mapSubOrders (subOrders) {
      const mapped = {}
      const mainSkus = []

      // Use Index and Added Warehouse Property
      subOrders.forEach((subOrder, index) => {
        mapped[`${subOrder.warehouse.id}${index}`] = subOrder.skus.map((sku) => ({
          ...sku,
          discountType: 'amount',
          discountPrice: sku.fullPrice - sku.price,
          unitPrice: sku.fullPrice / sku.amount,
          trackingNo: subOrder.shipping?.refCode || ''
        }))

        subOrder.skus.forEach((sku) => {
          const found = mainSkus.findIndex((mainSku) => mainSku.id === sku.id)
          if (found === -1) {
            mainSkus.push({
              ...sku,
              discountType: 'amount',
              discountPrice: sku.fullPrice - sku.price,
              unitPrice: sku.fullPrice / sku.amount
            })
          } else {
            mainSkus[found] = {
              ...mainSkus[found],
              mixAndMatch: mainSkus[found].mixAndMatch || sku.mixAndMatch,
              amount: mainSkus[found].amount + sku.amount,
              price: mainSkus[found].price + sku.price,
              discountPrice: mainSkus[found].discountPrice + sku.discountPrice || 0,
              fullPrice: mainSkus[found].fullPrice + sku.fullPrice
            }

            mainSkus[found].unitPrice = mainSkus[found].fullPrice / mainSkus[found].amount
          }
        })
      })

      mapped[0] = mainSkus

      return mapped
    },
    calcDiscountPerWarehouse (sku, detail) {
      const { discountType } = detail
      const discountPrice = discountType === 'amount' ? (detail.discountPrice / detail.amount) * sku.amount : detail.discountPrice

      return { ...sku, discountPrice, discountType }
    },
    openProductModal () {
      this.setProductModalActive(true)
    },
    imgSrcCompute (src) {
      return src || 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1200px-No-Image-Placeholder.svg.png'
    },
    getPreOrder (item) {
      return item?.preOrder
    }
  }
}
</script>

<style lang="scss">
.order-product-add-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #f7f7f7;
  border: 2px dashed #898989;
  border-radius: 5px;
  color: #898989;
  font-size: 14px;
  font-weight: 500;
  margin: 10px auto 0;
  padding: 5px;
  width: 97%;
}
#order-product-table p.text-price {
  margin: 4px 0;
}
#order-product-table.order-product-empty table {
  height: 100%;
  td {
    height: 100%;
    button.v-btn {
      height: 100%;
      position: relative;
      z-index: 2;
    }
  }
}
.font-super-bold {
  font-size: 14px;
  font-weight: 800;
}
</style>
