import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

export default class BCProvider extends HttpRequest {
  syncSalesOrder (orderId) {
    this.setHeader(getAuthToken())
    return this.post(`/order/bc/${orderId}/sync`)
  }

  getBCSwitch () {
    this.setHeader(getAuthToken())

    return this.get('/bc/switch')
  }

  updateBCSwitch (payload) {
    this.setHeader(getAuthToken())

    return this.put('/bc/switch', payload)
  }
}
