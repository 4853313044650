<template>
  <v-card flat>
    <div
      v-for="(orderAttachment, index) in orderDHLAttachments"
      :key="`attachment-box-${index + 1}`"
      class="payment-box"
      :data-count="(index + 1)">
      <p>
        <span>Dates :</span>
        <span>{{ orderAttachment.createdAt | dateTimeFormat() }}</span>
      </p>
      <p>
        <span>Name :</span>
        <span>{{ orderAttachment.name }}</span>
      </p>
      <p>
        <span>Type :</span>
        <span>{{ orderAttachment.type | toCapitalize() }}</span>
      </p>
      <p>
        <span>Download :</span>
        <v-icon
          color="secondary"
          style="cursor: pointer"
          @click="downloadFile(orderAttachment.url, orderAttachment.name)">
          mdi-file-download-outline
        </v-icon>
      </p>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      orderDHLAttachments: 'Order/orderDHLAttachments'
    })
  },
  methods: {
    downloadFile (url, fileName) {
      const downloadLink = document.createElement('a')

      downloadLink.href = url
      downloadLink.download = fileName
      downloadLink.click()
    }
  }
}
</script>

<style scoped>

</style>
