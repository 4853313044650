<template>
  <v-simple-table
    id="order-summary-table"
    dense>
    <tbody width="300px">
      <tr>
        <td width="300">
          Note
        </td>
        <!-- <td class="text-end">
          {{ skusTotalPrice | showFullPriceFormat() }}
        </td> -->
        <td>
          <v-text-field
            :value="orderDetail.note"
            label="Order Note"
            class="order-summary-input"
            :disabled="inputDisable"
            autocomplete="off"
            dense
            solo
            outlined
            hide-details
            @input="updateNote($event)" />
        </td>
        <td width="60"></td>
      </tr>
      <tr>
        <td>Total</td>
        <td class="text-end">
          {{ skusTotalPrice | showFullPriceFormat() }}
        </td>
        <td width="60">
          {{ currencyUnit }}
        </td>
      </tr>
      <tr>
        <td>Promotion Code</td>
        <td>
          <div>
            <v-text-field
              :value="checkPromocode(orderDetail.promotionCode)"
              label="Promotion Code"
              class="order-summary-input promotion-code-input"
              autocomplete="off"
              :disabled="inputDisable"
              :loading="gettingCode"
              dense
              solo
              outlined
              hide-details
              @input="updatePromotionCode($event)" />
          </div>
        </td>
        <td width="60">
          <v-btn
            v-if="!inputDisabled"
            x-small
            :disabled="gettingCode || !editMode"
            @click="isVerifiedCode ? cancelVerifyCode() : verifyCode()">
            {{ isVerifiedCode ? 'Cancel' : 'Verify' }}
          </v-btn>
        </td>
      </tr>
      <tr>
        <td>Code Discount</td>
        <td class="text-end">
          {{ codeDiscount | showFullPriceFormat() }}
        </td>
        <td width="60">
          {{ currencyUnit }}
        </td>
      </tr>
      <tr>
        <td>
          <div class="d-flex align-center">
            <span>Shipping Cost</span>
            <div class="order-summary-select-box">
              <v-select
                :value="shipping"
                :items="shippingItems"
                :menu-props="{
                  offsetY: true,
                  contentClass: 'order-summary-select-menu'
                }"
                item-text="providerName"
                item-value="method"
                class="order-summary-select"
                return-object
                hide-details
                dense
                outlined
                :disabled="inputDisabled || !editMode"
                @change="setShipping($event)">
                <template v-slot:item="{ item }">
                  <p class="text-uppercase">
                    {{ item.providerName }}
                  </p>
                </template>
              </v-select>
            </div>
          </div>
        </td>
        <td class="text-end">
          <v-text-field
            :value="shippingFee"
            class="shippingfee-input order-summary-input"
            dense
            solo
            outlined
            hide-details
            :disabled="inputDisabled || !editMode"
            @keypress="$keypressNumber($event)"
            @input="setShippingFee($event)">
          </v-text-field>
        </td>
        <td width="60">
          {{ currencyUnit }}
        </td>
      </tr>
      <tr>
        <td>Net</td>
        <td class="text-end">
          {{ netPrice | showFullPriceFormat() }}
        </td>
        <td width="60">
          {{ currencyUnit }}
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    verifiedCode: {
      type: Boolean,
      default: false
    },
    inputDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      gettingCode: false
    }
  },
  computed: {
    ...mapGetters({
      shippingItems: 'Store/mapShipping',
      shipping: 'Order/shipping',
      skusTotalPrice: 'Order/skusTotalPrice',
      skusDiscount: 'Order/skusDiscount',
      codeDiscount: 'Order/codeDiscount',
      shippingFee: 'Order/shippingFee',
      netPrice: 'Order/netPrice',
      orderDetail: 'Order/orderDetail',
      isVerifiedCode: 'Order/isVerifiedCode',
      editMode: 'Order/onEditMode',
      orderDetailActive: 'Order/orderDetailActive',
      isGotMember: 'Order/isGotMember',
      currencyUnit: 'Order/currencyUnit'
    }),
    inputDisable () {
      return this.gettingCode || this.isVerifiedCode || this.inputDisabled || !this.editMode
    },
    currentState () {
      return this.orderDetail.currentState
    }
  },
  methods: {
    ...mapActions({
      setShipping: 'Order/setShipping',
      setShippingFee: 'Order/setShippingFee',
      setPromotionCode: 'Order/setPromotionCode',
      setNote: 'Order/setNote',
      getMember: 'Order/getMember',
      updateGotMember: 'Order/updateGotMember',
      validatePromotion: 'Order/validatePromotion',
      cancelVaildateCode: 'Order/cancelVaildateCode',
      setSnackbar: 'Components/setSnackbar'
    }),
    updateNote (value) {
      this.setNote(value)
    },
    updatePromotionCode (value) {
      this.setPromotionCode(value)
    },
    checkPromocode (promotion) {
      return promotion?.code || null
    },
    async verifyCode () {
      try {
        this.gettingCode = true
        await this.validatePromotion(this.orderDetail.promotionCode.code)
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code: ${error.code}, ${error.message}`,
          type: 'error'
        })
      } finally {
        this.gettingCode = false
      }
    },
    cancelVerifyCode () {
      this.cancelVaildateCode()
    }
  }
}
</script>

<style lang="scss">
#order-summary-table {
  background-color: #f0f0f0;
  table {
    tr:hover {
      background-color: transparent;
    }
    td {
      border-bottom: 0;
      height: 20px;
      font-size: 12px;
      .v-input.promotion-code-input input {
        text-align: center;
      }
    }
    td:last-child {
      padding: 0;
    }
  }
}
</style>
