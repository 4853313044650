export default (size) => {
  const sizeLowerCase = size.toLowerCase()
  switch (sizeLowerCase) {
    case 'xxs': return 1
    case '2xs': return 1
    case 'xs': return 2
    case 's': return 3
    case 'm': return 4
    case 'l': return 5
    case 'xl': return 6
    case 'xxl': return 7
    case '2xl': return 7
    case '0-1y': return 8
    case '1-2y': return 9
    case '2-3y': return 10
    case '3-4y': return 11
    case '4-5y': return 12
    case '5-6y': return 13
    case '6-7y': return 14
    case '35/36': return 15
    case '37/38': return 16
    case '38/39': return 17
    case '39/40': return 18
    case 'xs-s': return 19
    case 's-m': return 20
    case 'm-l': return 21
    case 'l-xl': return 22
    default:
      if (Number(sizeLowerCase)) {
        return Number(sizeLowerCase)
      }
      return 0
  }
}
